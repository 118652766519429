import { checkoutInitialState, CheckoutState } from './checkout.state';
import { appInitialState, AppState } from './app.state';
import { scheduleInitialState, ScheduleState } from './schedule.state';
import { userInitialState, UserState } from './user.state';
import { vehiclesInitialState, VehiclesState } from './vehicles.state';
import { cmsInitialState, CmsState } from './cms.state';

export interface StoreState {
  app: AppState;
  cms: CmsState;
  user: UserState;
  vehicles: VehiclesState;
  checkout: CheckoutState;
  schedule: ScheduleState;
}

export const storeInitialState: StoreState = {
  app: appInitialState,
  cms: cmsInitialState,
  user: userInitialState,
  vehicles: vehiclesInitialState,
  checkout: checkoutInitialState,
  schedule: scheduleInitialState,
};

import { Product } from '@core/models/checkout.model';

export interface CheckoutState {
  /** Available products */
  availableProducts?: Product[];
}

export const checkoutInitialState: CheckoutState = {
  availableProducts: [],
};

export interface Notification {
  /** User id */
  userId: string;
  /** Tipo de notificacion */
  type: NotificationType;
  /** Urgencia de la notificacion */
  urgency: NotificationUrgency;
  /** Titulo */
  title: string;
  /** Subtitulo */
  subtitle: string;
  /** Fecha */
  date: string;
  /** Id del vehiculo */
  vehicleId?: string;
}

type NotificationType = 'itv' | 'insurance' | 'creditCard' | 'pickUp';
type NotificationUrgency = 'default' | 'warning' | 'error' | 'high';

export enum LocalNotificationTypes {
  ITV_NEAR_EXPIRES,
  ITV_EXPIRED,
  INSURANCE_NEAR_EXPIRES,
  INSURANCE_EXPIRED,
  MAINTENANCE_DATE,
  APPOINTMENT_ALERT_DATE,
}

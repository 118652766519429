import {
  banOutline,
  cloudUploadOutline,
  cloudOfflineOutline,
  peopleCircleOutline,
  cogOutline,
  closeOutline,
  closeCircleOutline,
  star,
  starOutline,
  filterCircle,
  filterCircleOutline,
  searchOutline,
  chevronDown,
  chevronDownOutline,
  remove,
  add,
  bagCheckOutline,
  linkOutline,
  clipboardOutline,
  imageOutline,
  search,
  shareOutline,
  copyOutline,
  mapSharp,
  earthSharp,
  person,
  lockOpen,
  openOutline,
  chevronForwardOutline,
  logoInstagram,
  logoYoutube,
  logoWhatsapp,
  logoApple,
  refreshOutline,
  chatbubbles,
  ribbonSharp,
} from 'ionicons/icons';

const emptyListIllustration = 'assets/icons/empty-list-illustration.svg';
const googleLogo = 'assets/icons/google-logo.svg';
const gdNumbers = 'assets/icons/gd-numbers.svg';
const gdAbc = 'assets/icons/gd-abc.svg';
const gdAdOff = 'assets/icons/gd-ad-off.svg';
const gdArrowForward = 'assets/icons/gd-arrow-forward.svg';
const gdArrowLeft = 'assets/icons/gd-arrow-left.svg';
const gdArrowsSort = 'assets/icons/gd-arrows-sort.svg';
const gdAt = 'assets/icons/gd-at.svg';
const gdBalloon = 'assets/icons/gd-balloon.svg';
const gdBatteryAutomotive = 'assets/icons/gd-battery-automotive.svg';
const gdBellRinging = 'assets/icons/gd-bell-ringing.svg';
const gdBell = 'assets/icons/gd-bell.svg';
const gdBike = 'assets/icons/gd-bike.svg';
const gdBox = 'assets/icons/gd-box.svg';
const gdBuildingWarehouse = 'assets/icons/gd-building-warehouse.svg';
const gdBuilding = 'assets/icons/gd-building.svg';
const gdBus = 'assets/icons/gd-bus.svg';
const gdCalendarEvent = 'assets/icons/gd-calendar-event.svg';
const gdCalendarOne = 'assets/icons/gd-calendar-one.svg';
const gdCamera = 'assets/icons/gd-camera.svg';
const gdCar = 'assets/icons/gd-car.svg';
const gdCarCrash = 'assets/icons/gd-car-crash.svg';
const gdCash = 'assets/icons/gd-cash.svg';
const gdCheck = 'assets/icons/gd-check.svg';
const gdChecks = 'assets/icons/gd-checks.svg';
const gdCircleCheckFilled = 'assets/icons/gd-circle-check-filled.svg';
const gdCircleCheck = 'assets/icons/gd-circle-check.svg';
const gdCircleSquare = 'assets/icons/gd-circle-square.svg';
const gdCircleX = 'assets/icons/gd-circle-x.svg';
const gdClients = 'assets/icons/gd-clients.svg';
const gdClockHour = 'assets/icons/gd-clock-hour.svg';
const gdCreditCard = 'assets/icons/gd-credit-card.svg';
const gdDeviceMobile = 'assets/icons/gd-device-mobile.svg';
const gdDotsCircleHorizontal = 'assets/icons/gd-dots-circle-horizontal.svg';
const gdDropletHalfTwoFilled = 'assets/icons/gd-droplet-half-two-filled.svg';
const gdEdit = 'assets/icons/gd-edit.svg';
const gdEmail = 'assets/icons/gd-email.svg';
const gdEngine = 'assets/icons/gd-engine.svg';
const gdExclamationCircle = 'assets/icons/gd-exclamation-circle.svg';
const gdEyeFilled = 'assets/icons/gd-eye-filled.svg';
const gdEye = 'assets/icons/gd-eye.svg';
const gdFileDescription = 'assets/icons/gd-file-description.svg';
const gdFileInfo = 'assets/icons/gd-file-info.svg';
const gdFileInvoice = 'assets/icons/gd-file-invoice.svg';
const gdFilePercent = 'assets/icons/gd-file-percent.svg';
const gdFileText = 'assets/icons/gd-file-text.svg';
const gdFile = 'assets/icons/gd-file.svg';
const gdFilesFolderFilled = 'assets/icons/gd-files-folder-filled.svg';
const gdFilesFolder = 'assets/icons/gd-files-folder.svg';
const gdGasStation = 'assets/icons/gd-gas-station.svg';
const gdHammer = 'assets/icons/gd-hammer.svg';
const gdInfoCircle = 'assets/icons/gd-info-circle.svg';
const gdManualGearbox = 'assets/icons/gd-manual-gearbox.svg';
const gdMapPin = 'assets/icons/gd-map-pin.svg';
const gdMotorbike = 'assets/icons/gd-motorbike.svg';
const gdPhone = 'assets/icons/gd-phone.svg';
const gdPhoto = 'assets/icons/gd-photo.svg';
const gdPlus = 'assets/icons/gd-plus.svg';
const gdPowerCv = 'assets/icons/gd-power-cv.svg';
const gdQuestionCircle = 'assets/icons/gd-question-circle.svg';
const gdReceipt = 'assets/icons/gd-receipt.svg';
const gdSchedule = 'assets/icons/gd-schedule.svg';
const gdSettingsCircle = 'assets/icons/gd-settings-circle.svg';
const gdShieldCheckFilled = 'assets/icons/gd-shield-check-filled.svg';
const gdShieldHalfFilled = 'assets/icons/gd-shield-half-filled.svg';
const gdShoppingBag = 'assets/icons/gd-shopping-bag.svg';
const gdSteeringWheel = 'assets/icons/gd-steering-wheel.svg';
const gdTags = 'assets/icons/gd-tags.svg';
const gdTransfer = 'assets/icons/gd-transfer.svg';
const gdTrash = 'assets/icons/gd-trash.svg';
const gdTruck = 'assets/icons/gd-truck.svg';
const gdUserCircle = 'assets/icons/gd-user-circle.svg';
const gdUserPlus = 'assets/icons/gd-user-plus.svg';
const gdUser = 'assets/icons/gd-user.svg';
const gdVerifiedAccount = 'assets/icons/gd-verified-account.svg';
const gdWallet = 'assets/icons/gd-wallet.svg';
const gdWand = 'assets/icons/gd-wand.svg';
const gdWiper = 'assets/icons/gd-wiper.svg';
const gdWorldWww = 'assets/icons/gd-world-www.svg';

export const GD_ICONS = {
  emptyListIllustration,
  googleLogo,
  gdAbc,
  gdAdOff,
  gdArrowForward,
  gdArrowLeft,
  gdArrowsSort,
  gdAt,
  gdBalloon,
  gdBatteryAutomotive,
  gdBellRinging,
  gdBell,
  gdBike,
  gdBox,
  gdBuildingWarehouse,
  gdBuilding,
  gdBus,
  gdCalendarEvent,
  gdCalendarOne,
  gdCamera,
  gdCar,
  gdCarCrash,
  gdCash,
  gdCheck,
  gdChecks,
  gdCircleCheckFilled,
  gdCircleCheck,
  gdCircleSquare,
  gdCircleX,
  gdClients,
  gdClockHour,
  gdCreditCard,
  gdDeviceMobile,
  gdDotsCircleHorizontal,
  gdDropletHalfTwoFilled,
  gdEdit,
  gdEmail,
  gdEngine,
  gdExclamationCircle,
  gdEyeFilled,
  gdEye,
  gdFileDescription,
  gdFileInfo,
  gdFileInvoice,
  gdFilePercent,
  gdFileText,
  gdFile,
  gdFilesFolderFilled,
  gdFilesFolder,
  gdGasStation,
  gdHammer,
  gdInfoCircle,
  gdManualGearbox,
  gdMapPin,
  gdMotorbike,
  gdNumbers,
  gdPhone,
  gdPhoto,
  gdPlus,
  gdPowerCv,
  gdQuestionCircle,
  gdReceipt,
  gdSchedule,
  gdSettingsCircle,
  gdShieldCheckFilled,
  gdShieldHalfFilled,
  gdShoppingBag,
  gdSteeringWheel,
  gdTags,
  gdTransfer,
  gdTrash,
  gdTruck,
  gdUserCircle,
  gdUserPlus,
  gdUser,
  gdVerifiedAccount,
  gdWallet,
  gdWand,
  gdWiper,
  gdWorldWww,
};

export const IONIC_ICONS = {
  banOutline,
  cloudUploadOutline,
  cloudOfflineOutline,
  peopleCircleOutline,
  cogOutline,
  closeOutline,
  closeCircleOutline,
  star,
  starOutline,
  filterCircle,
  filterCircleOutline,
  searchOutline,
  chevronDown,
  chevronDownOutline,
  remove,
  add,
  bagCheckOutline,
  linkOutline,
  clipboardOutline,
  imageOutline,
  search,
  shareOutline,
  copyOutline,
  mapSharp,
  earthSharp,
  person,
  lockOpen,
  openOutline,
  chevronForwardOutline,
  logoInstagram,
  logoYoutube,
  logoWhatsapp,
  logoApple,
  refreshOutline,
  chatbubbles,
  ribbonSharp,
};

export const ALL_ICONS = {
  ...IONIC_ICONS,
  ...GD_ICONS,
};

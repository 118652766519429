import { createReducer, on } from '@ngrx/store';
import { setAvailableProducts } from '@store/actions/checkout.actions';
import { checkoutInitialState } from '@store/state/checkout.state';

const checkoutReducerCreator = createReducer(
  checkoutInitialState,
  on(setAvailableProducts, (state, action) => ({
    ...state,
    availableProducts: action.products,
  })),
);

export function checkoutReducer(state, action) {
  return checkoutReducerCreator(state, action);
}

import { Action } from '@ngrx/store';
import { LS_KEYS, StorageService } from '@core/services/storage/storage.service';
import { setConfigurations } from '@store/actions/cms.actions';
import { deleteAccountConfirm, fetchUser, logout } from '@store/actions/user.actions';
import { storeInitialState, StoreState } from '@store/state/store.state';
import { setDarkModeStatus } from './actions/app.actions';

let isRehydrated = false;

export const storageMetaReducer = (storageService: StorageService) => reducer => (state: StoreState, action: Action) => {
  // On first get user request, set initial state
  if (action.type === fetchUser.type) {
    isRehydrated = true;
    const initialState: StoreState = getInitialState(storageService);
    storageService.setItem(LS_KEYS.STORE, JSON.stringify(initialState));
    return initialState;
  }

  // If action is setConfigurations, set configs
  if (action.type === setConfigurations.type) {
    const savedStore: StoreState = JSON.parse(storageService.getItem(LS_KEYS.STORE)) || {};
    const storeWithCurrentAppConfigs = {
      ...state,
      app: savedStore.app
    };
    const newState: StoreState = reducer(storeWithCurrentAppConfigs, action);
    storageService.setItem(LS_KEYS.STORE, JSON.stringify(newState));
    return newState;
  }

  // If action is logout, reset store
  if (action.type === logout.type || action.type === deleteAccountConfirm.type) {
    const resetState: StoreState = {
      ...storeInitialState,
      app: { ...storeInitialState.app, isDarkModeEnabled: state.app?.isDarkModeEnabled },
      cms: { ...storeInitialState.cms, configurations: { ...state.cms.configurations } },
    };
    const newState: StoreState = reducer(resetState, action);
    storageService.setItem(LS_KEYS.STORE, JSON.stringify(newState));
    return newState;
  }

  // Update the store on set dark mode action
  if (action.type === setDarkModeStatus.type) {
    const newState: StoreState = reducer(state, action);
    storageService.setItem(LS_KEYS.STORE, JSON.stringify(newState));
    return newState;
  }

  // Update the store on each action if user exists
  if (isRehydrated && state?.user) {
    const newState: StoreState = reducer(state, action);
    storageService.setItem(LS_KEYS.STORE, JSON.stringify(newState));
    return newState;
  }

  return reducer(state, action);
};

const getInitialState = (storageService: StorageService) => {
  let state: StoreState = storeInitialState;
  // If we have store on storage we get it
  const savedStore: StoreState = JSON.parse(storageService.getItem(LS_KEYS.STORE)) || {};
  if (savedStore) {
    state = {
      app: {
        ...state.app,
        showLoading: false,
        isDarkModeEnabled: savedStore.app?.isDarkModeEnabled,
        componentStatus: savedStore.app.componentStatus,
        collapseMenu: savedStore.app?.collapseMenu
      },
      cms: { ...state.cms, configurations: { ...savedStore.cms?.configurations } },
      user: { ...state.user, ...savedStore.user },
      vehicles: { ...state.vehicles, ...savedStore.vehicles },
      checkout: { ...state.checkout, ...savedStore.checkout },
      schedule: { ...state.schedule, ...savedStore.schedule },
    };
  }

  storageService.setItem(LS_KEYS.STORE, JSON.stringify(state));

  return state;
};

import { HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { CacheService } from '@core/services/cache/cache.service';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

/**
 * Cache interceptor function
 */
export function cacheInterceptor (request: HttpRequest<unknown>, next: HttpHandlerFn) {
  /** Dependencies */
  const cacheService = inject(CacheService);

  // Check if the URL is cached
  if (!cacheService.isUrlCached(request.url)) {
    return next(request);
  }

  // Check if the request is a GET request because we only cache GET requests
  if (request.method !== 'GET') {
    return next(request);
  }

  return cacheService.get(request.url).pipe(
    switchMap(cachedResponse => {
      if (cachedResponse) {
        // If there is a cached response, return it
        return of(new HttpResponse({ status: 200, body: cachedResponse }));
      } else {
        // If there is no cached response, make the request and cache the response
        return next(request).pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              cacheService.set(request.url, event.body);
            }
          }),
          catchError(error => of(error))
        );
      }
    })
  );
}

import { createReducer, on } from '@ngrx/store';
import { AccountData, User } from '@core/models/user.model';
import { setUser, setUserAccountData, setUserNotifications } from '@store/actions/user.actions';
import { UserState, userInitialState } from '@store/state/user.state';
import { setWorkshopVerifiedMaintenance } from '@store/actions/workshop.actions';
import { Maintenance } from '@core/models/maintenance.model';

const userReducerCreator = createReducer(
  userInitialState,
  on(setUser, (_state, action) => ({
    ...action.user,
  })),
  on(setUserAccountData, (state, action) => ({
    ...state,
    ...userAccountDataMapped(action.accountData, state),
  })),
  on(setUserNotifications, (state, action) => ({
    ...state,
    notifications: action.notifications,
  })),
  on(setWorkshopVerifiedMaintenance, (state, action) => ({
    ...state,
    workshopData: {
      ...state.workshopData,
      verifiedMaintenances: updateVerifiedMaintenanceList(state.workshopData?.verifiedMaintenances, action.maintenance)
    }
  })),
);

export function userReducer(state, action) {
  return userReducerCreator(state, action);
}

const userAccountDataMapped = (accountData: AccountData, state: UserState) => {
  accountData = accountData || state.accountData;
  const userTransformed: User = {
    ...state,
    accountData,
  };
  return userTransformed;
};

const updateVerifiedMaintenanceList = (verifiedMaintenances: Maintenance[], maintenance: Maintenance) => {
  const verifiedMaintenanceList = [...verifiedMaintenances];
  const maintenanceIndex = verifiedMaintenanceList.findIndex(m => m.id === maintenance?.id);
  verifiedMaintenanceList[maintenanceIndex] = maintenance;

  return verifiedMaintenanceList;
};
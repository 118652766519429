import { Injectable, inject } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectValidUserType } from '@store/selectors/user.selectors';
import { StoreState } from '@store/state/store.state';
import { Observable, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomPreloadingStrategyService implements PreloadingStrategy {

  /** Dependencies */
  private store = inject(Store<StoreState>);

  preload(route: Route, fn: () => Observable<unknown>): Observable<unknown> {
    return this.store.pipe(
      selectValidUserType,
      map((userType) => {
        const { preload, preloadUserType } = route.data;
        // If route should be preloaded and matches with the userType or no user type is provided preload
        if (preload && (!preloadUserType || preloadUserType === userType)) {
          return fn();
        }
        return of(null);
      })
    );
  }

}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '@core/services/request/request.service';
import { AppointmentBody } from '@core/models/schedule.model';
import { ScheduleAppointmentsApiResponse } from '@core/models-api/schedule-api.model';
import { UserApiResponse } from '@core/models-api/user-api.model';


@Injectable({ providedIn: 'root' })
export class ApiScheduleService {

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
   * Fetch schedule appointments
   */
  public fetchAppointments(): Observable<ScheduleAppointmentsApiResponse> {
    const url = 'schedule/appointments';
    return this.requestService.get(url, {}, false);
  }

  /**
   * Create schedule appointment
   */
  public createAppointment(appointment: AppointmentBody): Observable<ScheduleAppointmentsApiResponse> {
    const url = 'schedule/appointment';
    return this.requestService.post(url, appointment);
  }

  /**
   * Update schedule appointment
   */
  public updateAppointment(appointmentId: string, appointment: AppointmentBody): Observable<ScheduleAppointmentsApiResponse> {
    const url = `schedule/appointment/${appointmentId}`;
    return this.requestService.put(url, appointment);
  }

  /**
   * Delete schedule appointment
   */
  public deleteAppointment(appointmentId: string): Observable<ScheduleAppointmentsApiResponse> {
    const url = `schedule/appointment/${appointmentId}`;
    return this.requestService.delete(url);
  }

  /**
   * Confirm picked up
   */
  public confirmPickedUp(appointmentId: string): Observable<UserApiResponse> {
    const url = `schedule/confirmPickedUp/${appointmentId}`;
    return this.requestService.put(url, {});
  }

}

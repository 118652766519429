import { ComponentStatus, Filters, Sorts } from '@core/models/app.model';
import { maintenancesFiltersInitialState } from '@core/models/maintenance.model';
import { vehiclesFiltersInitialState } from '@core/models/vehicle.model';

export interface AppState {
  showLoading: boolean;
  isDarkModeEnabled: boolean;
  filters: Filters;
  sort: Sorts;
  componentStatus: ComponentStatus;
  collapseMenu: boolean;
}

export const appInitialState: AppState = {
  showLoading: false,
  isDarkModeEnabled: false,
  filters: {
    vehiclesFilters: vehiclesFiltersInitialState,
    maintenancesFilters: maintenancesFiltersInitialState,
    vehicleMaintenancesFilters: maintenancesFiltersInitialState,
    workshopVerifiedMaintenancesFilters: maintenancesFiltersInitialState,
  },
  sort: {
    vehiclesSort: 'brand',
    maintenancesSort: 'recent',
    vehicleMaintenancesSort: 'recent',
    workshopVerifiedMaintenancesSort: 'recent',
  },
  componentStatus: {
    accordion: [],
  },
  collapseMenu: false,
};

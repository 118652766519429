import { User } from '@core/models/user.model';

export interface UserState extends User {}

export const userInitialState: UserState = {
  id: undefined,
  username: undefined,
  name: undefined,
  lastName: undefined,
  fullName: undefined,
  email: undefined,
  phoneNumber: undefined,
  workshopData: undefined,
  accountData: undefined,
};

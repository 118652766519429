import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '@core/services/request/request.service';
import { MaintenanceBody } from '@core/models/maintenance.model';
import { VehicleApiResponse } from '@core/models-api/vehicle-api.model';


@Injectable({ providedIn: 'root' })
export class ApiMaintenancesService {

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
   * Create maintenances
   */
  public createMaintenance(vehicleId: string, body: MaintenanceBody): Observable<VehicleApiResponse> {
    const url = `vehicle/${vehicleId}/maintenance`;
    return this.requestService.post(url, body);
  }

  /**
   * Update maintenances
   */
  public updateMaintenance(maintenanceId: string, body: MaintenanceBody): Observable<VehicleApiResponse> {
    const url = `maintenance/${maintenanceId}`;
    return this.requestService.put(url, body);
  }

  /**
   * Update maintenances
   */
  public updateMaintenanceStatus(maintenanceId: string, done: boolean): Observable<VehicleApiResponse> {
    const url = `maintenance/${maintenanceId}/status`;
    return this.requestService.put(url, { done });
  }

  /**
   * Delete maintenance
   */
  public deleteVehicleMaintenance(vehicleId: string, maintenanceId: string): Observable<VehicleApiResponse> {
    const url = `vehicle/${vehicleId}/maintenance/${maintenanceId}`;
    return this.requestService.delete(url);
  }

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { createFolder, deleteFile, deleteFolder, updateFolder } from '@store/actions/files-folder.actions';
import { StoreService } from '@store/services/store.service';
import { ApiFilesFolderService } from '@core/services-api/files-folder/api-files-folder.service';


@Injectable()
export class FilesFolderEffects {

  /**
   * Create folder
   */
  createFolder$ = createEffect(() => this.actions$.pipe(
    ofType(createFolder),
    switchMap(({ parentFolderId, name, entityType, entityId }) =>
      this.apiFilesFolderService.createFolder(parentFolderId, name, entityType, entityId).pipe(
        this.storeService.setFilesFoldersStoreOperator()
      )),
  ));

  /**
   * Update folder
   */
  updateFolder$ = createEffect(() => this.actions$.pipe(
    ofType(updateFolder),
    switchMap(({ folderId, name, entityType, entityId }) =>
      this.apiFilesFolderService.updateFolder(folderId, name, entityType, entityId).pipe(
        this.storeService.setFilesFoldersStoreOperator()
      )),
  ));

  /**
   * Delete folder
   */
  deleteFolder$ = createEffect(() => this.actions$.pipe(
    ofType(deleteFolder),
    switchMap(({ folderId, entityType, entityId }) =>
      this.apiFilesFolderService.deleteFolder(folderId, entityType, entityId).pipe(
        this.storeService.setFilesFoldersStoreOperator()
      )),
  ));

  /**
   * Delete file
   */
  deleteFile$ = createEffect(() => this.actions$.pipe(
    ofType(deleteFile),
    switchMap(({ filesUrl, folderId, entityType, entityId }) =>
      this.apiFilesFolderService.deleteFile(filesUrl, folderId, entityType, entityId).pipe(
        this.storeService.setFilesFoldersStoreOperator()
      )),
  ));

  constructor(
    private storeService: StoreService,
    private actions$: Actions,
    private apiFilesFolderService: ApiFilesFolderService,
  ) {}

}

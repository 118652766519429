import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, catchError, withLatestFrom, take, map } from 'rxjs/operators';
import {
  applyDiscountToSubscription,
  cancelPremiumSubscription,
  checkSubscriptionStatus,
  confirm3dsPayment,
  confirmApplePurchase,
  createPremiumSubscription,
  deletePaymentMethod,
  purchaseProduct,
  reactivateSubscription,
  startFreeTrialWithoutCreditCard,
  updatePaymentData,
  fetchAvailableProducts,
  setAvailableProducts,
} from '@store/actions/checkout.actions';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { StoreService } from '@store/services/store.service';
import { StoreState } from '@store/state/store.state';
import { Store } from '@ngrx/store';
import { ModalTypes } from '@core/models/modal.model';
import { of, throwError } from 'rxjs';
import { selectValidUser } from '@store/selectors/user.selectors';
import { ApiCheckoutService } from '@core/services-api/checkout/api-checkout.service';
import { StripeService } from 'ngx-stripe';
import { fetchUser } from '@store/actions/user.actions';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { ProductTypes } from '@core/models/checkout.model';
import { ProductNameTypes } from '@core/models/user.model';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';
import { ModalService } from '@core/services/presentable-services/modal/modal.service';
import { CheckoutTransformer } from '@core/transformers/checkout.transformer';

@Injectable()
export class CheckoutEffects {

  /**
   * Start free trial without credit card
   */
  startFreeTrialWithoutCreditCard$ = createEffect(() => this.actions$.pipe(
    ofType(startFreeTrialWithoutCreditCard),
    switchMap(() => this.apiCheckoutService.startFreeTrialWithoutCreditCard().pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.SUBSCRIPTION-SUCCESS', 5000)),
      tap(async () => await this.modalService.dismiss(ModalTypes.CHECKOUT)),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Create premium subscription
   */
  createPremiumSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(createPremiumSubscription),
    switchMap(({ productKey, couponId }) => this.apiCheckoutService.createPremiumSubscription(productKey, couponId).pipe(
      switchMap(async (res) => {
        if (res.subscription.status === 'incomplete') {
          return this.stripeService.confirmCardPayment(res.clientSecret).pipe(
            take(1),
            tap(paymentIntentResult => this.handlePaymentIntentResult(paymentIntentResult, 'subscription', productKey)),
          );
        }
        this.toastService.presentSuccess('SUCCESS.SUBSCRIPTION-SUCCESS', 5000);
        await this.modalService.dismiss(null, null, ModalTypes.CHECKOUT);
        this.handlePurchaseSuccess(productKey);
        this.store.dispatch(fetchUser({}));
        return of (res);
      }),
    )),
  ), { dispatch: false });

  /**
   * Cancel premium subscription
   */
  cancelPremiumSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(cancelPremiumSubscription),
    switchMap(() => this.apiCheckoutService.cancelPremiumSubscription().pipe(
      catchError(e => {
        this.toastService.presentError('ERROR.UNSUBSCRIPTION', 5000);
        return throwError(() => e);
      }),
      tap(() => this.toastService.presentSuccess('SUCCESS.UNSUBSCRIPTION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Check subscrition status
   */
  checkSubscriptionStatus$ = createEffect(() => this.actions$.pipe(
    ofType(checkSubscriptionStatus),
    switchMap(({ showLoading }) => this.apiCheckoutService.checkSubscriptionStatus(showLoading).pipe(
      tap(({ ok }) => !ok && this.toastService.presentError('ERROR.CANCELLED-SUBSCRIPTION', 10000)),
      this.storeService.setAccountDataStoreOperator(),
    )),
  ));

  /**
   * Update payment data
   */
  updatePaymentData$ = createEffect(() => this.actions$.pipe(
    ofType(updatePaymentData),
    withLatestFrom(this.store.pipe(selectValidUser)),
    switchMap(([{ paymentData }, user]) => this.apiCheckoutService.updatePaymentData(paymentData).pipe(
      tap(async () => {
        // Show success only when update existing payment method
        if (user.accountData.subscriptionData.cardLastFour) {
          this.toastService.presentSuccess('SUCCESS.OPERATION');
        } else {
          await this.modalService.dismiss(null, null, ModalTypes.PAYMENT_FORM);
        }
      }),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Delete payment method
   */
  deletePaymentmethod$ = createEffect(() => this.actions$.pipe(
    ofType(deletePaymentMethod),
    switchMap(() => this.apiCheckoutService.deletePaymentMethod().pipe(
      tap(async () => {
        this.toastService.presentSuccess('SUCCESS.OPERATION');
        await this.modalService.dismiss(null, null, ModalTypes.PAYMENT_FORM);
      }),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update payment data
   */
  reactivateSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(reactivateSubscription),
    switchMap(({ productKey }) => this.apiCheckoutService.reactivateSubscription(productKey).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.SUBSCRIPTION-SUCCESS', 5000)),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Apply discount to subscription
   */
  applyDiscountToSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(applyDiscountToSubscription),
    switchMap(({ subscriptionId, couponId }) =>
      this.apiCheckoutService.applyDiscountToSubscription(subscriptionId, couponId).pipe(
        tap(() => this.toastService.presentSuccess('SUCCESS.DISCOUNT-APPLIED')),
        this.storeService.setCompleteStoreOperator(),
      )),
  ));

  /**
   * Confirm product purchase
   */
  purchaseProduct$ = createEffect(() => this.actions$.pipe(
    ofType(purchaseProduct),
    switchMap(({ productKey }) =>
      this.apiCheckoutService.purchaseProduct(productKey).pipe(
        switchMap((res) => {
          // If purchase required 3ds action handle
          if (res.purchase.status === 'requires_action') {
            return this.stripeService.confirmCardPayment(res.clientSecret).pipe(
              take(1),
              tap(paymentIntentResult => this.handlePaymentIntentResult(paymentIntentResult, 'product', productKey)),
            );
          }
          if (res.purchase.status === 'succeeded') {
            // Show success alert
            this.toastService.presentSuccess('SUCCESS.PURCHASE-PRODUCT');
            // Refresh complete user
            this.store.dispatch(fetchUser({}));
            // Handle purchase success
            this.handlePurchaseSuccess(productKey);
            return of(res);
          }
          this.toastService.presentError('ERROR.PURCHASE-INCOMPLETE');
          return of(null);
        }),
      )),
  ), { dispatch: false });
  
  /**
   * Confirm 3ds purchase
   */
  confirm3dsPayment$ = createEffect(() => this.actions$.pipe(
    ofType(confirm3dsPayment),
    switchMap(({ paymentIntent, paymentType, productKey }) =>
      this.apiCheckoutService.confirm3dsPayment(paymentIntent, paymentType).pipe(
        tap(async () => {
          if (paymentType === 'product') {
            this.toastService.presentSuccess('SUCCESS.PURCHASE-PRODUCT');
          }
          if (paymentType === 'subscription') {
            this.toastService.presentSuccess('SUCCESS.PAYMENT');
            await this.modalService.dismiss(null, null, ModalTypes.CHECKOUT);
            this.handlePurchaseSuccess(productKey);
            this.store.dispatch(checkSubscriptionStatus({ showLoading: true }));
          }
        }),
        this.storeService.setCompleteStoreOperator(),
      )),
  ));

  /**
   * Confirm apple purchase
   */
  confirmApplePurchase$ = createEffect(() => this.actions$.pipe(
    ofType(confirmApplePurchase),
    switchMap(({ appleTransaction }) =>
      this.apiCheckoutService.confirmApplePurchase(appleTransaction).pipe(
        tap(() => this.toastService.presentSuccess('SUCCESS.APPLE-PURCHASE')),
        tap(() => this.handlePurchaseSuccess(appleTransaction?.productId)),
        this.storeService.setCompleteStoreOperator(),
      )),
  ));

  /**
   * Fetch available products
   */
  fetchAvailableProducts$ = createEffect(() => this.actions$.pipe(
    ofType(fetchAvailableProducts),
    switchMap(() => this.apiCheckoutService.fetchAvailableProducts().pipe(
      map((availableProductsResponse) => {
        const products = CheckoutTransformer.productsFromApi(availableProductsResponse?.products);
        return setAvailableProducts({ products });
      }),
    )),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<StoreState>,
    private storeService: StoreService,
    private apiCheckoutService: ApiCheckoutService,
    private toastService: ToastService,
    private modalService: ModalService,
    private stripeService: StripeService,
    private adsService: AdsService,
  ) {}

  /**
   * Handle the payment intent result
   */
  private async handlePaymentIntentResult(paymentIntentResult: PaymentIntentResult, paymentType: ProductTypes, productKey: ProductNameTypes) {
    // Confirm 3ds payment if payment intent is defined
    if (paymentIntentResult.paymentIntent) {
      this.store.dispatch(confirm3dsPayment({ paymentIntent: paymentIntentResult.paymentIntent, paymentType, productKey }));
    } else {
      // If is not defined refresh subscription status
      this.toastService.presentError('ERROR.INVALID-PAYMENT-INTENT');
      await this.modalService.dismiss(null, null, ModalTypes.CHECKOUT);
      this.store.dispatch(checkSubscriptionStatus({ showLoading: true }));
    }
  }

  /**
   * Handle purchase success
   */
  private handlePurchaseSuccess(productKey: ProductNameTypes) {
    // Ads case
    if (productKey === 'REMOVE_ADS' || productKey === 'APPLE_REMOVE_ADS' || productKey === 'CLIENT_FLEET_SUBSCRIPTION') {
      this.adsService.destroyAds();
    }
  }

}

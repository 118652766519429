import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  confirmPickedUp,
  createAppointment,
  deleteAppointment,
  fetchAppointments,
  setAppointments,
  updateAppointment
} from '@store/actions/schedule.actions';
import { Appointment } from '@core/models/schedule.model';
import { ScheduleTransformer } from '@core/transformers/schedule.transformer';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/state/store.state';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { DateCustomPipe } from '@shared/pipes/date-custom.pipe';
import { ApiScheduleService } from '@core/services-api/schedule/api-schedule.service';
import { LocalNotificationsService } from '@core/services/plugin-services/local-notifications/local-notifications.service';
import { StoreService } from '@store/services/store.service';


@Injectable()
export class ScheduleEffects {

  /**
   * Fetch appointments
   */
  fetchAppointments$ = createEffect(() => this.actions$.pipe(
    ofType(fetchAppointments),
    switchMap(() => this.apiScheduleService.fetchAppointments().pipe(
      withLatestFrom(this.store),
      map(([res, store]) => ScheduleTransformer.scheduleAppointmentsFromApi(res.appointments, store)),
      map((appointments: Appointment[]) => setAppointments({ appointments })),
    )),
  ));

  /**
   * Create appointment
   */
  createAppointment$ = createEffect(() => this.actions$.pipe(
    ofType(createAppointment),
    switchMap(({ appointment }) => this.apiScheduleService.createAppointment(appointment).pipe(
      withLatestFrom(this.store),
      map(([res, store]) => ScheduleTransformer.scheduleAppointmentsFromApi(res.appointments, store)),
      tap(() => {
        this.toastService.presentInfo(
          'SCHEDULE.NEW-APPOINTMENT-AT', 3000, { date: this.dateCustomPipe.transform(appointment.date) }
        );
      }),
      tap((appointments: Appointment[]) => this.localNotificationsService.scheduleAppointmentNotifications(appointments, true)),
      map((appointments: Appointment[]) => setAppointments({ appointments })),
    )),
  ));

  /**
   * Update appointment
   */
  updateAppointment$ = createEffect(() => this.actions$.pipe(
    ofType(updateAppointment),
    switchMap(({ appointmentId, appointment }) =>
      this.apiScheduleService.updateAppointment(appointmentId, appointment).pipe(
        withLatestFrom(this.store),
        map(([res, store]) => ScheduleTransformer.scheduleAppointmentsFromApi(res.appointments, store)),
        tap((appointments: Appointment[]) => this.localNotificationsService.scheduleAppointmentNotifications(appointments, true)),
        map((appointments: Appointment[]) => setAppointments({ appointments })),
      )),
  ));

  /**
   * Delete appointment
   */
  deleteAppointment$ = createEffect(() => this.actions$.pipe(
    ofType(deleteAppointment),
    switchMap(({ appointmentId }) => this.apiScheduleService.deleteAppointment(appointmentId).pipe(
      withLatestFrom(this.store),
      map(([res, store]) => ScheduleTransformer.scheduleAppointmentsFromApi(res.appointments, store)),
      tap((appointments: Appointment[]) => this.localNotificationsService.scheduleAppointmentNotifications(appointments, true)),
      map((appointments: Appointment[]) => setAppointments({ appointments })),
    )),
  ));

  /**
   * Confirm picked up
   */
  confirmPickedUp$ = createEffect(() => this.actions$.pipe(
    ofType(confirmPickedUp),
    switchMap(({ appointmentId }) => this.apiScheduleService.confirmPickedUp(appointmentId).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.CONFIRM-PICKED-UP')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  constructor(
    private actions$: Actions,
    private localNotificationsService: LocalNotificationsService,
    private apiScheduleService: ApiScheduleService,
    private store: Store<StoreState>,
    private toastService: ToastService,
    private dateCustomPipe: DateCustomPipe,
    private storeService: StoreService,
  ) {}

}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '@core/services/request/request.service';
import { VehicleBody, VehicleCommonIssuesFieldsBody } from '@core/models/vehicle.model';
import { UserApiResponse } from '@core/models-api/user-api.model';
import { VehicleApiResponse } from '@core/models-api/vehicle-api.model';

@Injectable({ providedIn: 'root' })
export class ApiVehiclesService {

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
   * Fetch vehicles
   */
  public fetchVehicles() {
    const url = 'vehicles';
    return this.requestService.get(url);
  }

  /**
   * Fetch vehicle maintenances
   */
  public fetchVehicle(vehicleId: string, showLoading: boolean = false): Observable<VehicleApiResponse> {
    const url = `vehicle/${vehicleId}`;
    return this.requestService.get(url, {}, showLoading);
  }

  /**
   * Create vehicle
   */
  public createVehicle(body: VehicleBody, ownerId: string): Observable<UserApiResponse> {
    const url = `vehicle/${ownerId}`;
    return this.requestService.post(url, body);
  }

  /**
   * Update vehicle
   */
  public updateVehicle(vehicleId: string, body: VehicleBody): Observable<VehicleApiResponse> {
    const url = `vehicle/${vehicleId}`;
    return this.requestService.put(url, body);
  }

  /**
   * Delete vehicle
   */
  public deleteVehicle(vehicleId: string): Observable<UserApiResponse> {
    const url = `vehicle/${vehicleId}`;
    return this.requestService.delete(url);
  }

  /**
   * Transfer vehicle request
   */
  public transferVehicleRequest(vehicleId: string, originUserId: string, targetUserId: string): Observable<UserApiResponse> {
    const url = 'transferVehicleRequest';
    const body = { vehicleId, originUserId, targetUserId };
    return this.requestService.put(url, body);
  }

  /**
   * Transfer vehicle confirm
   */
  public transferVehicleConfirm(operationId: string, originUserId: string, targetUserId: string, vehicleId: string): Observable<UserApiResponse> {
    const url = 'transferVehicleConfirm';
    const body = { operationId, originUserId, targetUserId, vehicleId };
    return this.requestService.put(url, body);
  }

  /**
   * Transfer vehicle reject
   */
  public transferVehicleReject(operationId: string, originUserId: string): Observable<UserApiResponse> {
    const url = 'transferVehicleReject';
    const body = { operationId, originUserId };
    return this.requestService.put(url, body);
  }

  /**
   * Transfer vehicle request
   */
  public shareVehicleRequest(vehicleId: string, targetUserId: string): Observable<UserApiResponse> {
    const url = 'shareVehicleRequest';
    const body = { vehicleId, targetUserId };
    return this.requestService.put(url, body);
  }

  /**
   * Transfer vehicle confirm
   */
  public shareVehicleConfirm(operationId: string, originUserId: string, targetUserId: string, vehicleId: string): Observable<UserApiResponse> {
    const url = 'shareVehicleConfirm';
    const body = { operationId, originUserId, targetUserId, vehicleId };
    return this.requestService.put(url, body);
  }

  /**
   * Transfer vehicle reject
   */
  public shareVehicleReject(operationId: string, originUserId: string) {
    const url = 'shareVehicleReject';
    const body = { operationId, originUserId };
    return this.requestService.put(url, body);
  }

  /**
   * Remove vehicle authorization
   */
  public removeVehicleAuthorization(vehicleId: string, authorizedUserId: string): Observable<UserApiResponse> {
    const url = 'removeVehicleAuthorization';
    const body = { vehicleId, authorizedUserId };
    return this.requestService.put(url, body);
  }

  /**
   * Update vehicle
   */
  public updateVehicleCommonIssuesFields(vehicleId: string, body: VehicleCommonIssuesFieldsBody): Observable<VehicleApiResponse> {
    const url = `vehicleCommonIssuesRequiredFields/${vehicleId}`;
    return this.requestService.put(url, body, {}, false);
  }

  /**
   * Fetch common issues
   */
  public fetchCommonIssues(vehicleId: string): Observable<VehicleApiResponse> {
    const url = `vehicleCommonIssues/${vehicleId}`;
    return this.requestService.get(url, {}, false);
  }

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  createVehicle,
  deleteVehicle,
  fetchCommonIssues,
  fetchVehicle,
  fetchVehicles,
  removeVehicleAuthorization,
  setVehicles,
  shareVehicleConfirm,
  shareVehicleReject,
  shareVehicleRequest,
  transferVehicleConfirm,
  transferVehicleReject,
  transferVehicleRequest,
  updateVehicle,
  updateVehicleCommonIssuesFields
} from '@store/actions/vehicles.actions';
import { Vehicle } from '@core/models/vehicle.model';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { StoreService } from '@store/services/store.service';
import { VehicleApi, VehiclesApiResponse } from '@core/models-api/vehicle-api.model';
import { Router } from '@angular/router';
import { ApiVehiclesService } from '@core/services-api/vehicles/api-vehicles.service';
import { throwError } from 'rxjs';
import { ModalTypes } from '@core/models/modal.model';
import { ModalService } from '@core/services/presentable-services/modal/modal.service';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';


@Injectable()
export class VehiclesEffects {

  /**
   * Fetch vehicles
   */
  fetchVehicles$ = createEffect(() => this.actions$.pipe(
    ofType(fetchVehicles),
    switchMap(() => this.apiVehiclesService.fetchVehicles().pipe(
      map((res: VehiclesApiResponse) => res?.vehicles),
      map((vehicles: VehicleApi[]) => setVehicles({ vehicles: vehicles as unknown as Vehicle[] })),
    )),
  ));

  /**
   * Fetch vehicle
   */
  fetchVehicle$ = createEffect(() => this.actions$.pipe(
    ofType(fetchVehicle),
    switchMap(({ vehicleId, showLoading }) => this.apiVehiclesService.fetchVehicle(vehicleId, showLoading).pipe(
      this.storeService.setVehicleStoreOperator(),
    )),
  ));

  /**
   * Create vehicle
   */
  createVehicle$ = createEffect(() => this.actions$.pipe(
    ofType(createVehicle),
    switchMap(({ vehicle, ownerId }) => this.apiVehiclesService.createVehicle(vehicle, ownerId).pipe(
      tap(({ createdEntityId }) => {
        // Navigate to new vehicle detail
        if (createdEntityId) {
          this.router.navigateByUrl(`/detail-vehicle/${createdEntityId}`);
        } else {
          this.toastService.presentSuccess('SUCCESS.NEW-VEHICLE');
        }
      }),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update vehicle
   */
  updateVehicle$ = createEffect(() => this.actions$.pipe(
    ofType(updateVehicle),
    switchMap(({ vehicleId, vehicle }) => this.apiVehiclesService.updateVehicle(vehicleId, vehicle).pipe(
      tap(async () => this.toastService.presentSuccess('SUCCESS.UPDATE-VEHICLE')),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Delete vehicle
   */
  deleteVehicle$ = createEffect(() => this.actions$.pipe(
    ofType(deleteVehicle),
    switchMap(({ vehicleId }) => this.apiVehiclesService.deleteVehicle(vehicleId).pipe(
      tap(async () => this.toastService.presentSuccess('SUCCESS.DELETE-VEHICLE')),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Transfer vehicle request
   */
  transferVehicleRequest$ = createEffect(() => this.actions$.pipe(
    ofType(transferVehicleRequest),
    switchMap(({ vehicleId, originUserId, targetUserId }) => this.apiVehiclesService.transferVehicleRequest(
      vehicleId, originUserId, targetUserId
    ).pipe(
      tap(() => this.toastService.presentInfo('SUCCESS.TRANSFER-VEHICLE-REQUEST', 5000)),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Transfer vehicle confirm
   */
  transferVehicleConfirm$ = createEffect(() => this.actions$.pipe(
    ofType(transferVehicleConfirm),
    switchMap(({ operationId, originUserId, targetUserId, vehicleId }) =>
      this.apiVehiclesService.transferVehicleConfirm(operationId, originUserId, targetUserId, vehicleId).pipe(
        tap(() => this.toastService.presentInfo('SUCCESS.TRANSFER-VEHICLE-CONFIRM', 10000)),
        this.storeService.setCompleteStoreOperator({ shouldRefreshVehicleId: vehicleId }),
      )),
  ));

  /**
   * Transfer vehicle confirm
   */
  transferVehicleReject$ = createEffect(() => this.actions$.pipe(
    ofType(transferVehicleReject),
    switchMap(({ operationId, originUserId, vehicleId }) => this.apiVehiclesService.transferVehicleReject(operationId, originUserId).pipe(
      tap(() => this.toastService.presentInfo('SUCCESS.TRANSFER-VEHICLE-REJECT')),
      this.storeService.setCompleteStoreOperator({ shouldRefreshVehicleId: vehicleId }),
    )),
  ));

  /**
   * Share vehicle request
   */
  shareVehicleRequest$ = createEffect(() => this.actions$.pipe(
    ofType(shareVehicleRequest),
    switchMap(({ vehicleId, targetUserId }) => this.apiVehiclesService.shareVehicleRequest(vehicleId, targetUserId).pipe(
      tap(() => this.toastService.presentInfo('SUCCESS.SHARE-VEHICLE-REQUEST')),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Share vehicle confirm
   */
  shareVehicleConfirm$ = createEffect(() => this.actions$.pipe(
    ofType(shareVehicleConfirm),
    switchMap(({ operationId, originUserId, targetUserId, vehicleId }) =>
      this.apiVehiclesService.shareVehicleConfirm(operationId, originUserId, targetUserId, vehicleId).pipe(
        tap(() => this.toastService.presentInfo('SUCCESS.SHARE-VEHICLE-CONFIRM')),
        this.storeService.setCompleteStoreOperator({ shouldRefreshVehicleId: vehicleId }),
      )),
  ));

  /**
   * Share vehicle confirm
   */
  shareVehicleReject$ = createEffect(() => this.actions$.pipe(
    ofType(shareVehicleReject),
    switchMap(({ operationId, originUserId }) => this.apiVehiclesService.shareVehicleReject(operationId, originUserId).pipe(
      tap(() => this.toastService.presentInfo('SUCCESS.SHARE-VEHICLE-REJECT')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Remove vehicle authorization
   */
  removeVehicleAuthorization$ = createEffect(() => this.actions$.pipe(
    ofType(removeVehicleAuthorization),
    switchMap(({ vehicleId, authorizedUserId }) => this.apiVehiclesService.removeVehicleAuthorization(vehicleId, authorizedUserId).pipe(
      tap(() => this.toastService.presentInfo('SUCCESS.SHARE-VEHICLE-REJECT')),
      this.storeService.setCompleteStoreOperator({ shouldRefreshVehicleId: vehicleId }),
    )),
  ));

  /**
   * Update vehicle common issues required fields
   */
  updateVehicleCommonIssuesFields$ = createEffect(() => this.actions$.pipe(
    ofType(updateVehicleCommonIssuesFields),
    switchMap(({ vehicleId, vehicle }) => this.apiVehiclesService.updateVehicleCommonIssuesFields(vehicleId, vehicle).pipe(
      this.storeService.setVehicleStoreOperator(),
    )),
  ));

  /**
   * Update vehicle common issues required fields
   */
  fetchCommonIssues$ = createEffect(() => this.actions$.pipe(
    ofType(fetchCommonIssues),
    switchMap(({ vehicleId }) => this.apiVehiclesService.fetchCommonIssues(vehicleId).pipe(
      catchError(async (e) => {
        await this.modalService.dismiss(null, null, ModalTypes.VEHICLE_COMMON_ISSUES);
        return throwError(() => e);
      }),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setVehicleStoreOperator(),
    )),
  ));

  constructor(
    private actions$: Actions,
    private storeService: StoreService,
    private apiVehiclesService: ApiVehiclesService,
    private toastService: ToastService,
    private modalService: ModalService,
    private router: Router,
    private adsService: AdsService,
  ) {}

}

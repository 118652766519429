import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '@core/services/request/request.service';
import { RefuelingBody } from '@core/models/refueling.model';
import { UserApiResponse } from '@core/models-api/user-api.model';


@Injectable({ providedIn: 'root' })
export class ApiRefuelingService {

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
   * Create refueling
   */
  public createRefueling(body: RefuelingBody): Observable<UserApiResponse> {
    const url = 'refueling';
    return this.requestService.post(url, body);
  }

  /**
   * Update refueling
   */
  public updateRefueling(refuelingId: string, body: RefuelingBody): Observable<UserApiResponse> {
    const url = `refueling/${refuelingId}`;
    return this.requestService.put(url, body);
  }

  /**
   * Delete refueling
   */
  public deleteRefueling(refuelingId: string): Observable<UserApiResponse> {
    const url = `refueling/${refuelingId}`;
    return this.requestService.delete(url);
  }

}

import { AccordionTypes } from '@core/models/app.model';
import { maintenancesFiltersInitialState } from '@core/models/maintenance.model';
import { createReducer, on } from '@ngrx/store';
import { AppState, appInitialState } from '@store/state/app.state';
import {
  hideLoading,
  setAccordionStatus,
  toggleMenuStatus,
  setDarkModeStatus,
  setMaintenancesFilters,
  setMaintenancesSort,
  setVehicleMaintenancesFilters,
  setVehicleMaintenancesSort,
  setVehiclesSort,
  setWorkshopVerifiedMaintenancesFilters,
  setWorkshopVerifiedMaintenancesSort,
  showLoading,
  setVehiclesFilters,
} from '@store/actions/app.actions';

const appReducerCreator = createReducer(
  appInitialState,
  on(showLoading, (state) => ({ ...state, showLoading: true })),
  on(hideLoading, (state) => ({ ...state, showLoading: false })),
  on(setDarkModeStatus, (state, { enabled }) => ({ ...state, isDarkModeEnabled: enabled })),
  on(setVehiclesFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      vehiclesFilters: {
        ownVehicles: action.vehiclesFilters?.ownVehicles,
      },
    }
  })),
  on(setMaintenancesFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      maintenancesFilters: updateMaintenancesFilters(state, action),
    }
  })),
  on(setVehicleMaintenancesFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      vehicleMaintenancesFilters: updateVehicleMaintenancesFilters(state, action),
    }
  })),
  on(setWorkshopVerifiedMaintenancesFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      workshopVerifiedMaintenancesFilters: updateWorkshopVerifiedMaintenancesFilters(state, action),
    }
  })),
  on(setMaintenancesSort, (state, action) => ({
    ...state,
    sort: {
      ...state.sort,
      maintenancesSort: action.sort
    }
  })),
  on(setVehiclesSort, (state, action) => ({
    ...state,
    sort: {
      ...state.sort,
      vehiclesSort: action.sort
    }
  })),
  on(setVehicleMaintenancesSort, (state, action) => ({
    ...state,
    sort: {
      ...state.sort,
      vehicleMaintenancesSort: action.sort
    }
  })),
  on(setWorkshopVerifiedMaintenancesSort, (state, action) => ({
    ...state,
    sort: {
      ...state.sort,
      workshopVerifiedMaintenancesSort: action.sort
    }
  })),
  on(setAccordionStatus, (state, action) => ({
    ...state,
    componentStatus: {
      ...state?.componentStatus,
      accordion: updateAccordionStatus(state?.componentStatus?.accordion, action.name),
    }
  })),
  on(toggleMenuStatus, (state) => ({ ...state, collapseMenu: !state.collapseMenu })),
);

export function appReducer(state, action) {
  return appReducerCreator(state, action);
}

const updateMaintenancesFilters = (state: AppState, action) => {
  if (action?.isClearAction) {
    return maintenancesFiltersInitialState;
  }
  return {
    ...state.filters.maintenancesFilters,
    ...action.maintenancesFilters,
    hasActiveFilters: true
  };
};

const updateVehicleMaintenancesFilters = (state: AppState, action) => {
  if (action?.isClearAction) {
    return maintenancesFiltersInitialState;
  }
  return {
    ...state.filters.vehicleMaintenancesFilters,
    ...action.maintenancesFilters,
    hasActiveFilters: true
  };
};

const updateWorkshopVerifiedMaintenancesFilters = (state: AppState, action) => {
  if (action?.isClearAction) {
    return maintenancesFiltersInitialState;
  }
  return {
    ...state.filters.workshopVerifiedMaintenancesFilters,
    ...action.maintenancesFilters,
    hasActiveFilters: true
  };
};

const updateAccordionStatus = (accordions: AccordionTypes[], name: AccordionTypes) => {
  let newAccordions = [...accordions];
  const isAccordeonOpen = accordions.some(ac => ac === name);

  // Toggle accordeon status
  if (isAccordeonOpen) {
    newAccordions = newAccordions.filter(ac => ac !== name && ac);
  } else if (name) {
    newAccordions.push(name);
  }

  return newAccordions;
};
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import {
  createClientByWorkshop,
  createStockItem,
  createStockItemTransaction,
  deleteClient,
  deleteStockItem,
  deleteStockItemTransaction,
  generateMaintenanceInvoice,
  sendWorkshopReview,
  unverifyMaintenance,
  updateClientByWorkshop,
  updateStockItem,
  updateStockItemTransaction,
  verifyMaintenance,
} from '@store/actions/workshop.actions';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { StoreService } from '@store/services/store.service';
import { ApiWorkshopService } from '@core/services-api/workshop/api-workshop.service';
import { ModalTypes } from '@core/models/modal.model';
import { ModalService } from '@core/services/presentable-services/modal/modal.service';
import { fetchVehicle } from '@store/actions/vehicles.actions';
import { StoreState } from '@store/state/store.state';
import { Store } from '@ngrx/store';

@Injectable()
export class WorkshopEffects {

  /**
   * Create client by workshop
   */
  createClientByWorkshop$ = createEffect(() => this.actions$.pipe(
    ofType(createClientByWorkshop),
    switchMap(({ user, vehicle }) => this.apiWorkshopService.createClientByWorkshop(user, vehicle).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.CREATE-CLIENT-BY-WORKSHOP')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update client by workshop
   */
  updateClientByWorkshop$ = createEffect(() => this.actions$.pipe(
    ofType(updateClientByWorkshop),
    switchMap(({ user, clientId }) => this.apiWorkshopService.updateClientByWorkshop(user, clientId).pipe(
      tap(() => this.apiWorkshopService.shouldUpdateClient$.next(clientId)),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Delete client account
   */
  deleteClient$ = createEffect(() => this.actions$.pipe(
    ofType(deleteClient),
    switchMap(({ clientId }) => {
      return this.apiWorkshopService.deleteClientByWorkshop(clientId).pipe(
        tap(() => this.toastService.presentSuccess('SUCCESS.DELETE-CLIENT-BY-WORKSHOP')),
        this.storeService.setCompleteStoreOperator(),
      );
    }),
  ));

  /**
   * Update maintenance status
   */
  verifyMaintenance$ = createEffect(() => this.actions$.pipe(
    ofType(verifyMaintenance),
    switchMap(({ maintenanceId, vehicleId }) =>
      this.apiWorkshopService.verifyMaintenance(maintenanceId).pipe(
        tap(() => this.toastService.presentSuccess('SUCCESS.VERIFY-MAINTENANCE')),
        // Fetch updated vehicle
        tap(() => this.store.dispatch(fetchVehicle({ vehicleId }))),
        this.storeService.setCompleteStoreOperator(),
      )),
  ));

  /**
   * Unverify maintenance
   */
  unverifyMaintenance$ = createEffect(() => this.actions$.pipe(
    ofType(unverifyMaintenance),
    switchMap(({ maintenanceId, vehicleId }) => this.apiWorkshopService.unverifyMaintenance(maintenanceId).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      // Fetch updated vehicle
      tap(() => this.store.dispatch(fetchVehicle({ vehicleId }))),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Generate maintenance invoice
   */
  generateMaintenanceInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(generateMaintenanceInvoice),
    switchMap(({ vehicleId, maintenanceId, invoiceNumber, invoiceFile }) =>
      this.apiWorkshopService.generateMaintenanceInvoice(maintenanceId, invoiceNumber, invoiceFile).pipe(
        tap(async () => {
          this.toastService.presentSuccess('SUCCESS.GENERATED-MAINTENANCE-INVOICE');
          await this.modalService.dismiss(null, null, ModalTypes.INVOICE_CONFIG);
        }),
        // Fetch updated vehicle
        tap(() => this.store.dispatch(fetchVehicle({ vehicleId }))),
        this.storeService.setCompleteStoreOperator(),
      )),
  ));

  /**
   * Send workshop review
   */
  sendWorkshopReview$ = createEffect(() => this.actions$.pipe(
    ofType(sendWorkshopReview),
    switchMap(({ review }) => this.apiWorkshopService.sendWorkshopReview(review).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.SEND-WORKSHOP-REVIEW')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Create workshop stock item
   */
  createStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(createStockItem),
    switchMap(({ item }) => this.apiWorkshopService.createStockItem(item).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update workshop stock item
   */
  updateStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(updateStockItem),
    switchMap(({ itemId, item }) => this.apiWorkshopService.updateStockItem(itemId, item).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Delete workshop stock item
   */
  deleteStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(deleteStockItem),
    switchMap(({ itemId }) => this.apiWorkshopService.deleteStockItem(itemId).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));
  
  /**
   * Create stock item transaction
   */
  createStockItemTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(createStockItemTransaction),
    switchMap(({ itemId, transaction }) => this.apiWorkshopService.createStockItemTransaction(itemId, transaction).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update stock item transaction
   */
  updateStockItemTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(updateStockItemTransaction),
    switchMap(({ itemId, transactionId, transaction }) => this.apiWorkshopService.updateStockItemTransaction(itemId, transactionId, transaction).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Delete stock item transaction
   */
  deleteStockItemTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(deleteStockItemTransaction),
    switchMap(({ itemId, transactionId }) => this.apiWorkshopService.deleteStockItemTransaction(itemId, transactionId).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.OPERATION')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<StoreState>,
    private storeService: StoreService,
    private apiWorkshopService: ApiWorkshopService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {}

}

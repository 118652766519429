import { Configurations, CountryConfigurations } from '@core/models/cms.model';

export interface CmsState {
  configurations: Configurations;
  countryConfigurations: CountryConfigurations;
}

export const cmsInitialState: CmsState = {
  configurations: {},
  countryConfigurations: {},
};

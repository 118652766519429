import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { CustomPreloadingStrategyService } from '@core/services/custom-preloading-strategy/custom-preloading-strategy.service';
import { APP_ROUTES } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '@env/environment';
import { provideNgcCookieConsent } from 'ngx-cookieconsent';
import { COOKIES_CONFIG } from '@helpers/common.helpers';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { requestInterceptor } from '@core/interceptors/request.interceptor';
import { NgrxStoreModule } from '@store/store.module';
import { cacheInterceptor } from '@core/interceptors/cache.interceptor';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideIonicAngular(),
    provideAnimationsAsync(),
    provideRouter(APP_ROUTES, withPreloading(CustomPreloadingStrategyService), withComponentInputBinding()),
    provideHttpClient(withInterceptors([
      cacheInterceptor,
      requestInterceptor
    ])),
    provideNgcCookieConsent(COOKIES_CONFIG),
    provideNgxStripe(environment.stripeKey),
    importProvidersFrom(
      CoreModule,
      NgrxStoreModule,
    ),
  ],
};

import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { LS_KEYS, StorageService } from '../services/storage/storage.service';
import { ToastService } from '../services/presentable-services/toast/toast.service';
import { StoreState } from '@store/state/store.state';
import { fetchUser } from '@store/actions/user.actions';
import { UserService } from '@pages/user/services/user.service';
import { ConnectivityService } from '@core/services/connectivity/connectivity.service';
import { hideLoading } from '@store/actions/app.actions';

/**
 * Request interceptor function
 */
export function requestInterceptor (request: HttpRequest<unknown>, next: HttpHandlerFn) {
  /** Dependencies */
  const toastService = inject(ToastService);
  const userService = inject(UserService);
  const store = inject(Store<StoreState>);
  const storageService = inject(StorageService);
  const connectivityService = inject(ConnectivityService);

  // Custom request response
  const token = storageService.getItem(LS_KEYS.TOKEN);
  if (token) {
    request = request.clone({ setHeaders: { token } });
  }

  // Handle error
  return next(request).pipe(
    catchError((error: HttpErrorResponse) => handleResponseError(error, toastService, userService, store, connectivityService))
  );
}

/**
 * Handle error responses
 *
 * @param error HttpErrorResponse codes
 * @returns error obserable
 */
function handleResponseError(
  error: HttpErrorResponse,
  toastService: ToastService,
  userService: UserService,
  store: Store<StoreState>,
  connectivityService: ConnectivityService,
) {
  return connectivityService.hasConnectivity$.pipe(
    take(1),
    switchMap(hasConnectivity => {
      // If no connectivity return empty
      if (!hasConnectivity) {
        toastService.presentOffline();
        store.dispatch(hideLoading());
        return EMPTY;
      }

      // If error has user property update user
      if (error?.error?.shouldRefresh) {
        store.dispatch(fetchUser({}));
      }
    
      // Handle permission errors
      if (error?.status === 401) {
        toastService.presentError('ERROR.UNAUTHORIZED');
        return throwError(() => error);
      }
    
      // Handle auth errors
      if (error?.status === 403) {
        userService.removeTokensAndGoToLogin();
        toastService.presentError('ERROR.SESSION-EXPIRED');
        return throwError(() => error);
      }
    
      // Handle not found errors
      if (error?.status === 404) {
        const errorMessage = error.error?.errorKey || 'ERROR.NOT-FOUND';
        toastService.presentError(errorMessage, 6000);
        return throwError(() => error);
      }
    
      // Handle limit request errors
      if (error?.status === 429) {
        toastService.presentError('ERROR.LIMITER');
        return throwError(() => error);
      }
    
      // Handle api errors. If status is 0 only show if is online
      if ((error?.status >= 400 && error?.status < 500)) {
        const errorMessage = error.error?.errorKey || 'ERROR.OPERATION';
        const infoMessage = error.error?.infoKey;
        if (infoMessage) {
          toastService.presentInfo(infoMessage, 6000);
        } else {
          toastService.presentError(errorMessage, 6000);
        }
        return throwError(() => error);
      }
    
      // Handle server errors
      if (error?.status >= 500 && error?.status < 600) {
        const errorMessage = error.error?.errorKey || 'ERROR.SERVER';
        toastService.presentError(errorMessage, 6000);
        return throwError(() => error);
      }
    
      // Throw error
      return throwError(() => error);
    })
  );
}
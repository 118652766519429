import { createReducer, on } from '@ngrx/store';
import { setConfigurations, setCountryConfigurations } from '@store/actions/cms.actions';
import { cmsInitialState } from '@store/state/cms.state';

const cmsReducerCreator = createReducer(
  cmsInitialState,
  on(setConfigurations, (state, action) => ({
    ...state,
    configurations: action.configurations,
  })),
  on(setCountryConfigurations, (state, action) => ({
    ...state,
    countryConfigurations: action.countryConfigurations,
  })),
);

export function cmsReducer(state, action) {
  return cmsReducerCreator(state, action);
}

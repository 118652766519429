import { Injectable, inject } from '@angular/core';
import { WINDOW } from 'custom-window';
import { fromEvent, merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { ToastService } from '../presentable-services/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class ConnectivityService {

  /** Has connectivity observable */
  public hasConnectivity$: Observable<boolean>;

  /** Dependencies */
  private toastService = inject(ToastService);
  protected window = inject(WINDOW);

  constructor() {
    this.setConnectivitySubscription();
  }

  /**
   * Set connectivity subscription
   */
  public setConnectivitySubscription() {
    if (!this.window || !navigator || !('onLine' in navigator)) { return; }

    this.hasConnectivity$ = merge(fromEvent(this.window, 'online'), fromEvent(this.window, 'offline'))
      .pipe(
        startWith(true),
        map(() => navigator.onLine),
        distinctUntilChanged(),
        tap((online) => {
          if (!online) {
            this.toastService.presentOffline();
          } else {
            this.toastService.dismiss('offline-toast');
          }
        }),
      );
  }

}

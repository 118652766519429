import { Vehicle } from '@core/models/vehicle.model';
import { createReducer, on } from '@ngrx/store';
import { setVehicle, setVehicles } from '@store/actions/vehicles.actions';
import { vehiclesInitialState } from '@store/state/vehicles.state';

const vehiclesReducerCreator = createReducer(
  vehiclesInitialState,
  on(setVehicles, (state, action) => ({
    ...state,
    items: action.vehicles
  })),
  on(setVehicle, (state, { vehicle }) => ({
    ...state,
    items: updateVehicleListItem(state.items, vehicle)
  })),
);

export function vehiclesReducer(state, action) {
  return vehiclesReducerCreator(state, action);
}

const updateVehicleListItem = (vehiclesState: Vehicle[], vehicle: Vehicle) => {
  let vehicleList = [...vehiclesState];
  const vehicleIndex = vehicleList.findIndex(m => m.id === vehicle?.id);
  vehicleList[vehicleIndex] = vehicle;

  // If vehicle already exists (update) replace with index, otherwise add to the end
  if (vehicleIndex > -1) {
    vehicleList[vehicleIndex] = vehicle;
  } else {
    vehicleList = [...vehicleList, vehicle];
  }

  return vehicleList;
};

import { createReducer, on } from '@ngrx/store';
import { setAppointments } from '@store/actions/schedule.actions';
import { scheduleInitialState } from '@store/state/schedule.state';

const scheduleReducerCreator = createReducer(
  scheduleInitialState,
  on(setAppointments, (state, action) => ({
    ...state,
    appointments: action.appointments,
    myWorkshopsAppointments: action.myWorkshopsAppointments,
  })),
);

export function scheduleReducer(state, action) {
  return scheduleReducerCreator(state, action);
}

import { Appointment } from '@core/models/schedule.model';

export interface ScheduleState {
  appointments: Appointment[];
  myWorkshopsAppointments: Appointment[];
}

export const scheduleInitialState: ScheduleState = {
  appointments: [],
  myWorkshopsAppointments: [],
};

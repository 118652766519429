import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { StoreService } from '@store/services/store.service';
import { createRefueling, deleteRefueling, updateRefueling } from '@store/actions/refueling.actions';
import { ApiRefuelingService } from '@core/services-api/refueling/api-refueling.service';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { Router } from '@angular/router';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';


@Injectable()
export class RefuelingEffects {

  /**
   * Create refueling
   */
  createRefueling$ = createEffect(() => this.actions$.pipe(
    ofType(createRefueling),
    switchMap(({ body }) => this.apiRefuelingService.createRefueling(body).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.NEW-REFUELING')),
      tap(() => this.router.navigateByUrl('/refuelings')),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update refueling
   */
  updateRefueling$ = createEffect(() => this.actions$.pipe(
    ofType(updateRefueling),
    switchMap(({ refuelingId, body }) => this.apiRefuelingService.updateRefueling(refuelingId, body).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.UPDATE-REFUELING')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Delete refueling
   */
  deleteRefueling$ = createEffect(() => this.actions$.pipe(
    ofType(deleteRefueling),
    switchMap(({ refuelingId }) => this.apiRefuelingService.deleteRefueling(refuelingId).pipe(
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  constructor(
    private actions$: Actions,
    private storeService: StoreService,
    private apiRefuelingService: ApiRefuelingService,
    private toastService: ToastService,
    private router: Router,
    private adsService: AdsService,
  ) {}

}

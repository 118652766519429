import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EmptyApiResponse } from '@core/models-api/user-api.model';
import { RequestService } from '@core/services/request/request.service';

@Injectable({ providedIn: 'root' })
export class ApiPushNotificationsService {

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
   * Register token on user
   *
   * @param token Firebase Cloud Messaging token
   */
  public registerFCMToken(token: string) {
    const url = 'registerFCMToken';
    return this.requestService.post(url, { token }, {}, false);
  }

  /**
   * Remove token from user
   *
   * @param token Firebase Cloud Messaging token
   */
  public removeFCMToken(token: string): Observable<EmptyApiResponse> {
    const url = 'removeFCMToken';
    return this.requestService.post(url, { token }, {}, false);
  }

}
